#jsd-widget {
  display: none;
}

.jodit-placeholder {
  display: none !important;
}

.jodit-status-bar-link {
  display: none;
}
